<template>
  <div class="whiteBg">
    <template v-if="roleBtn[0].power">
      <div class="el-icon-plus settingAdd" @click="handleAdd"><span> 新增</span></div>
    </template>
    <div class="table_list" style="margin-top: 16px;">
      <el-table
        :data="labelList"
        :header-cell-style="{background:'#EBF2FE'}">
        <el-table-column
          label="编号"
          type="index">
        </el-table-column>
        <el-table-column
          label="标签名称"
          prop="slname">
        </el-table-column>
        <el-table-column
          label="标签图标"
          prop="furl">
          <template slot-scope="scope">
            <img :src="scope.row.furl" width="180" height="80" :onerror="errorImg" />
          </template>
        </el-table-column>
        <el-table-column
          label="是否显示"
          prop="islook">
          <template slot-scope="scope">
            <span v-if="scope.row.islook === true">是</span>
            <span v-if="scope.row.islook === false">否</span>
          </template>
        </el-table-column>
        <el-table-column
          label="生效时间"
          prop="slrules">
          <template slot-scope="scope">
            <span v-if="scope.row.slrules === true">{{ scope.row.slstime }}至{{ scope.row.sletime }}</span>
            <span v-if="scope.row.slrules === false">永久</span>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          label="操作">
          <template slot-scope="scope">
            <template v-if="roleBtn[2].power">
              <el-button type="text" size="small" @click="showEdit(scope.row)">编辑</el-button>
            </template>
            <template v-if="roleBtn[1].power">
              <el-button type="text" size="small" style="color: red;" @click="handDel(scope.row)">删除</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="pageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
    </div>
    <!-- 新增编辑弹框 -->
    <el-dialog :title="formTitle" :visible.sync="formShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="formCancel">
      <el-form :model="labelForm" :rules="labelRule" ref="serveLabel">
        <el-form-item label="标签名称" prop="slname">
          <el-input v-model="labelForm.slname"  placeholder="请输入标签名称"></el-input>
        </el-form-item>
        <el-form-item label="生效时间" prop="slrules" class="fiLabelLeft">
          <p><el-radio v-model="labelForm.slrules" :label="false">无限</el-radio></p>
          <div class="flexAC">
            <el-radio v-model="labelForm.slrules" :label="true">时间段</el-radio>
            <el-date-picker v-model="labelForm.se_time" type="daterange" style="width: 70%;" value-format="yyyy-MM-dd"
                            :disabled="!labelForm.slrules"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="标签图片" class="fiLabelLeft" prop="slimg">
          <template style="width: 100%;">
            <Upload :fileType="'jpg/png/jpeg'" :picture="labelForm.furl" @uploadSuccess="ImgSuccess"></Upload>
          </template>
        </el-form-item>
      </el-form>
      <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
        <div class="settingCancel" @click="formCancel">取消</div>
        <div class="settingConfirm" @click="labelConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '@/components/upload.vue'
import { serveLabelQuery, serveLabelAdd, serveLabelEdit, serveLabelDel } from '@/api/service'
export default {
  name: 'serveLabel',
  components: {
    Upload
  },
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      labelList: [],
      formShow: false,
      formTitle: '新增',
      labelForm: {
        slid: '',
        slname: '',
        slimg: '',
        slrules: false,
        se_time: [],
        slstime: '',
        sletime: '',
        furl: ''
      },
      labelRule: {
        slname: [
          { required: true, trigger: 'blur', message: '标签名称不能为空' },
          { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
        ],
        slrules: { required: true, trigger: 'blur', message: '请选择生效时间' },
        slimg: { required: true, trigger: 'blur', message: '请上传图片' }
      },
      errorImg: '"' + require('@/assets/img/users.png') + '"'
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = { page_index: this.page, page_size: this.size }
      serveLabelQuery(params).then(res => {
        if (res.status === 1) {
          this.labelList = res.data.data
          this.total = res.data.recordcount
        }
      })
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    ImgSuccess (res) {
      this.labelForm.slimg = res.fid
    },
    // 点击新增
    handleAdd () {
      this.formTitle = '新增'
      this.formShow = true
      this.labelForm = {
        slid: '',
        slname: '',
        slimg: '',
        slrules: false,
        se_time: [],
        slstime: '',
        sletime: '',
        furl: ''
      }
    },
    formCancel () {
      this.formShow = false
      this.$refs.serveLabel.resetFields()
    },
    // 点击编辑
    showEdit (row) {
      this.formTitle = '编辑'
      this.formShow = true
      this.labelForm = { ...row }
    },
    // [新增-编辑]提交
    labelConfirm () {
      this.$refs.serveLabel.validate((valid) => {
        if (!valid) return
        let _st = ''
        let _et = ''
        if (this.labelForm.slrules === true) {
          if (this.labelForm.se_time === undefined || this.labelForm.se_time === null) {
            this.$message.error('请选择时间')
            return
          } else {
            _st = this.labelForm.se_time[0]
            _et = this.labelForm.se_time[1]
          }
        }
        const params = {
          slid: this.labelForm.slid,
          slname: this.labelForm.slname,
          slimg: this.labelForm.slimg,
          slrules: this.labelForm.slrules,
          slstime: _st,
          sletime: _et
        }
        if (this.formTitle === '新增') {
          serveLabelAdd(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.formCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        } else if (this.formTitle === '编辑') {
          serveLabelEdit(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.formCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    handDel (row) {
      this.$confirm('此操作将永久删除该标签, 是否继续?', '删除标签', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        serveLabelDel({ slid: row.slid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>
